import React, { useState } from 'react';
import { navigate } from 'gatsby';
import { useTranslation } from 'react-i18next';
import { useQuery } from '@apollo/react-hooks';
import { makeStyles } from '@material-ui/core/styles';
import { Box, Chip, Container, Menu, MenuItem } from '@material-ui/core';
import {
  AddBoxOutlined,
  ArrowDropDown,
  ArrowDropUp,
  Autorenew,
  ContactMailTwoTone,
  DeleteTwoTone,
  EditTwoTone,
  Sort,
} from '@material-ui/icons';
import { SectionBar } from '../../componentsUI/SectionBar';
import { isEmpty } from '../../utils/ObjectUtils';
import { PageListContent } from '../Common/styled/PageContent';
import { PatientMedicalCasesCollapse } from './PatientMedicalCasesCollapse';
import { CardWrapperUI } from '../Common/styled/CardWrapperUI';
import Loading from '../Common/Loading';
import { GET_AUTHORIZED_PATIENTS_FOR_PREVIEW } from '../../graphql/queries';
import TableCollapse from '../../componentsUI/TableCollapse';
import {
  GetFormat,
  CHIP_HIGHLIGHT_TRANSPARENT,
  IS_REGISTERED,
  YEARS,
  DATE_FORMAT_CHIP, DATE_FORMAT,
} from '../../utils/functions';
import { NewPatientDialog } from './modal/PatientNewDialog';
import { DeletePatientDialog } from './modal/DeletePatientDialog';
import { InvitePatientDialog } from './modal/InvitePatientDialog';
import { Navbar } from '../Navbar/styled/NavbarStyles';
import { ContainerUI } from '../Common/styled/ContainerUI';
import { graphQLErrorParse } from '../../utils/ErrorGraphQLUtils';
import { AlertUI, AlertWrapperUI } from '../../componentsUI/Alert';

const useStyles = makeStyles((theme) => ({
  table: {
    '& p.patient-name': {
      fontWeight: 500,
      fontSize: '1.375rem',
    },
    '& div.MuiChip-root': {
      borderRadius: 6,
      '&.date': {
        fontSize: '1.15rem',
        minWidth: 90,
      },
    },
    '& div[class^="makeStyles-itemSelected"]': {
      '& div[class^="MuiChip-root makeStyles-transparentChip"]': {
        background: theme.palette.primary.light,
        color: 'white',
      },
    },
    '&div[class^="makeStyles-transparentChip"]': {
      background: 'yellow',
    },
  },
  title: {
    '& > span': {
      fontSize: '1.375rem',
      fontWeight: 600,
    },
    '& div.MuiChip-root': {
      marginRight: 8,
      minWidth: 100,
      height: 24,
      fontSize: '1.2rem',
      '& > span': {
        paddingLeft: 6,
        paddingRight: 6,
      },
      '&.name': {
        fontSize: '1.28rem',
        background: 'transparent',
        border: '1px solid',
        borderColor: `${theme.palette.grey.A200}57`,
        '@media (min-width:601px)': {
          minWidth: 240,
        },
      },
    },
  },
  subtitle: {
    '& div.MuiChip-root': {
      marginRight: 8,
      height: 22,
      fontSize: '1.185rem',
      fontWeight: 600,
      '& > span': {
        paddingLeft: 12,
        paddingRight: 12,
      },
    },
  },
  collapse: {
    fontSize: '1.1875rem',
    paddingLeft: 16,
    paddingBottom: 6,
  },
}));

const fieldNames = [
  { label: 'patient.id', id: 'node.customId', width: 125, field: 'PATIENT_ID', format: CHIP_HIGHLIGHT_TRANSPARENT, direction: 'ASC' },
  { label: null, id: 'node.userUuid', format: IS_REGISTERED, width: 10 },
  { label: 'aage', id: 'node.birthDate', field: 'BIRTH_DATE', width: 60, align: 'center', format: YEARS, direction: 'ASC' },
  { label: 'name', id: 'node.name', width: 130, field: 'NAME', direction: 'ASC' },
  { label: 'surname', id: 'node.surname', width: 150, field: 'SURNAME', direction: 'ASC' },
  { label: 'last.update.index', id: 'node.updatedAt', width: 105, format: DATE_FORMAT_CHIP, align: 'center' },
];

export const PatientsView = ({ hospitalUuid }) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);
  const openMenu = Boolean(anchorEl);
  const [selected, setSelected] = useState([]);
  const [graphResetStore, setGraphResetStore] = useState(false);
  const [showAddNewModal, setShowAddNewModal] = useState(false);
  const [refreshNewModal, setRefreshNewModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showInviteModal, setShowInviteModal] = useState(false);

  const sortData = {
    default: { field: 'NAME', direction: 'ASC' },
    fields: [{ key: 'NAME', text: 'Name' }, { key: 'SURNAME', text: 'Surname' }, { key: 'PATIENT_ID', text: 'Patient ID' }],
  };
  const [orderByField, setOrderByField] = useState(sortData.default.field);
  const [orderByDirection, setOrderByDirection] = useState(sortData.default.direction);

  // TODO: find out why this sometimes return network error in store
  const { called, loading, data, error, refetch, fetchMore, client } = useQuery(
    GET_AUTHORIZED_PATIENTS_FOR_PREVIEW, {
      variables: {
        orderBy: { field: orderByField, direction: orderByDirection },
        hospitalUuid,
      },
      fetchPolicy: 'cache-and-network',
      notifyOnNetworkStatusChange: true,
    },
  );

  const patients = data && data.authorizedPatients;

  if (called && !loading && !patients && !graphResetStore) {
    client.resetStore().then(() => refetch());
    setGraphResetStore(true);
  }

  const reverseDirection = () => (orderByDirection === 'ASC' ? 'DESC' : 'ASC');

  const handleOrderBy = (value) => {
    if (value.field) {
      if (value.field === orderByField) {
        setOrderByDirection(reverseDirection());
      } else {
        setOrderByDirection(value.direction);
      }

      setOrderByField(value.field);
      refetch();
      setAnchorEl();
      setSelected([]);
    }
  };

  const fetchMorePatients = (fetchMoreCb) => {
    const { endCursor } = patients.pageInfo;

    fetchMore({
      query: GET_AUTHORIZED_PATIENTS_FOR_PREVIEW,
      variables: {
        cursor: endCursor,
        orderBy: { field: orderByField, direction: orderByDirection },
        hospitalUuid,
      },
      updateQuery: (previousResult, { fetchMoreResult }) => {
        const newEdges = fetchMoreResult.authorizedPatients.edges;

        return newEdges.length ? {
          authorizedPatients: {
            // eslint-disable-next-line no-underscore-dangle
            __typename: previousResult.authorizedPatients.__typename,
            totalCount: previousResult.authorizedPatients.totalCount,
            pageInfo: fetchMoreResult.authorizedPatients.pageInfo,
            edges: [...previousResult.authorizedPatients.edges, ...newEdges],
          },
        } : previousResult;
      },
    })
      .then(({ data: fetchMoreData }) => (
        fetchMoreData.authorizedPatients.pageInfo.hasNextPage && fetchMoreCb()
      ));
  };

  const getTitle = (item) => (
    <Box className={classes.title}>
      <Chip label={GetFormat(item.node.customId)} color="primary" />
      <Chip label={`${item.node.name} ${item.node.surname}`} className="name" />
    </Box>
  );

  const getSubTitle = (item) => (
    <Box className={classes.subtitle}>
      <Chip label={`${t('aage')}: ${GetFormat(item.node.birthDate, YEARS)}`} />
    </Box>
  );

  const getCollapse = ({ item }) => {
    if (!item) return '';
    if (!item.node) return '';

    const register = item.node.userUuid ? 'patient.has.access.to.ahp' : 'patient.not.registered.in.ahp';

    return (
      <Box className={classes.collapse}>
        {t(register)}
        <br />
        {item.node.email && `${t('email')}: ${item.node.email}`}
        {item.node.email && <br />}
        {`${t('birth.date')}: ${GetFormat(item.node.birthDate, DATE_FORMAT)}`}
        <PatientMedicalCasesCollapse item={item} />
      </Box>
    );
  };

  const handleGoto = (evt, index) => {
    evt.stopPropagation();
    const indexPatient = patients && patients.edges[index].node;

    navigate(`/patient/${indexPatient.uuid}`);
  };

  const handleAddedNewPatient = () => {
    setShowAddNewModal(false);
    setRefreshNewModal(true);
    setTimeout(() => setRefreshNewModal(false), 250);
    refetch().then();
  };

  const handleCloseAddNew = () => {
    setShowAddNewModal(false);
  };

  const handleCloseDelete = () => {
    setSelected([]);
    setShowDeleteModal(false);
    refetch().then();
  };

  const handleCloseInvite = () => {
    setShowInviteModal(false);
  };

  const handleRefresh = () => {
    client.resetStore().then(refetch().then());
  };

  const handleOrder = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const patientsCountMessage = patients && patients.totalCount
    && `${patients.totalCount} ${t('registries').toLowerCase()}`;

  const patient = (patients && selected.length > 0) && patients.edges[selected[0]] && patients.edges[selected[0]].node;

  const isInviteOptionDisabled = (() => {
    if (!patient) return true;
    return !!patient.userUuid;
  })();

  const buttons = [
    { name: 'new.patient', icon: AddBoxOutlined, handleClick: () => navigate('/patients/new'), disabled: false },
    { name: 'divider2', type: 'divider' },
    { name: 'edit.patient', icon: EditTwoTone, I: 'edit', data: patient, handleClick: () => navigate(`/patient/edit/${patient.uuid}`), disabled: selected.length !== 1 },
    { name: 'delete.patient', icon: DeleteTwoTone, I: 'edit', data: patient, handleClick: () => setShowDeleteModal(true), disabled: selected.length === 0 },
    { name: 'invite.patient', icon: ContactMailTwoTone, I: 'view', data: patient, handleClick: () => setShowInviteModal(true), disabled: isInviteOptionDisabled },
    { name: 'divider2', type: 'divider' },
    { name: 'refresh', icon: Autorenew, handleClick: handleRefresh, disabled: false },
    { name: 'divider2', type: 'divider' },
    { name: 'sort.by', icon: Sort, handleClick: handleOrder, disabled: false },
  ];

  const OrderIcon = ({ className, direction }) => (
    (direction === 'ASC') ? <ArrowDropDown className={className} /> : <ArrowDropUp className={className} />
  );

  const errorMessage = graphQLErrorParse(error);

  if (errorMessage) {
    return (
      <AlertWrapperUI>
        <AlertUI severity="error" title="Error">{t(errorMessage)}</AlertUI>
      </AlertWrapperUI>
    );
  }

  if (refreshNewModal) setTimeout(() => setRefreshNewModal(false), 250);

  return (
    <ContainerUI>
      <Navbar>
        {!refreshNewModal && (
          <NewPatientDialog open={showAddNewModal} onClose={handleCloseAddNew} onCreated={handleAddedNewPatient} />
        )}
        <DeletePatientDialog open={showDeleteModal} onClose={handleCloseDelete} patient={patient} />
        <InvitePatientDialog open={showInviteModal} onClose={handleCloseInvite} patient={patient} />

        <Menu anchorEl={anchorEl} open={openMenu} onClose={() => setAnchorEl(null)}>
          {fieldNames.filter((filter) => !!filter.field).map((item, index) => (
            <MenuItem
              key={index.toString()}
              onClick={() => handleOrderBy(item)}
              style={{ display: 'flex', alignItems: 'center' }}
            >
              <span style={{ width: 150 }}>{t(item.label)}</span>
              {(item.field === orderByField) && <OrderIcon direction={orderByDirection} />}
            </MenuItem>
          ))}
        </Menu>

        <SectionBar title="patients" subtitle={patientsCountMessage} items={buttons} />
      </Navbar>
      <Container maxWidth="lg" className="article">
        <PageListContent>
          {loading && isEmpty(patients) ? <Loading /> : (
            <CardWrapperUI className={classes.table}>
              {patients
                && (
                  <TableCollapse
                    fieldNames={fieldNames}
                    items={patients && patients.edges}
                    GetTitle={getTitle}
                    GetSubtitle={getSubTitle}
                    GetCollapse={getCollapse}
                    selected={selected}
                    setSelected={setSelected}
                    handleGoto={handleGoto}
                    isMore={patients
                      && patients.pageInfo
                      && patients.pageInfo.hasNextPage}
                    fetchMoreFn={fetchMorePatients}
                    order={{ field: orderByField, direction: orderByDirection }}
                    handleOrder={handleOrderBy}
                  />
                )}
            </CardWrapperUI>
          )}
        </PageListContent>
      </Container>
    </ContainerUI>
  );
};
